import loadable from '@loadable/component';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'dayjs/locale/en-gb';
import React from 'react';
import { render } from 'react-dom';
import '../index.scss';
// polyfill/shim until https://github.com/donavon/use-event-listener/pull/55 is merged & released
window.global = window;

const App = loadable(() => import('../App'));
const Provider = loadable(() => import('../components/Provider/Provider'));

const Application = () => (
  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
    <Provider>
      <App />
    </Provider>
  </LocalizationProvider>
);

Object.entries({
  // "id selector": Component
  '#app-root': Application,
}).forEach(([selector, Component]) => {
  const element = document.querySelector(selector);
  if (element) {
    render(<Component />, element);
  }
});

// console.log('hello world');
